import React from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import "../../ProjectDashboard/ProjectDashboard.css";
import "./PromptManagement.css";
import { useNavigate } from "react-router-dom";

const PromptManagement = () => {
  const tabIds = {
    SystemPrompts: "SystemPrompts",
    InstantInsightsPrompts: "InstantInsightsPrompts",
    SaTPromptLibrary: "SaTPromptLibrary",
  };

  const navigate = useNavigate();

  const handlePromptManagementTabClick = (tab) => {
    switch (tab) {
      case tabIds.SystemPrompts:
        navigate("SystemPrompts");
        break;
      case tabIds.InstantInsightsPrompts:
        navigate("InstantInsightPrompts");
        break;
      case tabIds.SaTPromptLibrary:
        navigate("InstantInsightPrompts");
        break;
      default:
        navigate("SystemPrompts");
        break;
    }
  };

  return (
    <div className="ProjectDashboardPageContainer">
      <div className="HeaderLayout">
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Prompt Management</span>
          </div>
          <div className="HeaderAction"></div>
        </div>
      </div>
      <div className="prompt-managment-container">
        <MotifTabNavigation className="tab" defaultActiveKey={0} data-testid="tabsPrompts" id="tabsPromptManagement">
          <MotifTabControl
            onClick={() => handlePromptManagementTabClick(tabIds.SystemPrompts)}
            data-testid="tabSystemPrompts"
          >
            System Prompts
          </MotifTabControl>
          <MotifTabControl
            onClick={() =>
              handlePromptManagementTabClick(tabIds.InstantInsightsPrompts)
            }
          >
            Instant Insights Prompts
          </MotifTabControl>
          <MotifTabControl
            onClick={() =>
              handlePromptManagementTabClick(tabIds.SaTPromptLibrary)
            }
          >
            SaT Prompt Library
          </MotifTabControl>
        </MotifTabNavigation>
      </div>
    </div>
  );
};

export default PromptManagement;
