import React, { useEffect, useState } from "react";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setTargetSubSection } from "../../../../reducers/instantInsightSlice";

const MoveInstantInsightPromptModal = (props) => {
  const dispatch = useDispatch();
  const { targetSubSection } = useSelector((state) => state.instantInsight);
  const [categoryData, setCategoryData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [subSectionData, setSubSectionData] = useState([]);
  const [category, setCategory] = useState(0);
  const [section, setSection] = useState(0);
  const [subSection, setSubSection] = useState(0);
  const nodeType = { category: 1, section: 2, subSection: 3 };

  useEffect(() => {
    getCategories();
  }, [props.scopeTreeData]);

  useEffect(() => {
    if (targetSubSection && categoryData.length) {
      setSection(targetSubSection?.section?.id);
      setSubSection(targetSubSection?.subSection?.id);
    }
  }, [targetSubSection, categoryData]);

  /**
   * This method is to extract the category from the scope tree
   */
  const getCategories = () => {
    let categories = _.filter(props.scopeTreeData?.children, {
      nodeType: nodeType.category,
    });
    setCategory(categories[0].id);
    setCategoryData(categories);
  };

  /**
   * This method is to handle the category select change event
   * @param {*} value 
   */
  const handleCategoryChange = (value) => {
    let sections = _.map(_.filter(categoryData, { id: value }), "children");
    if (sections?.length) {
      setSectionData(sections[0]);
    }
  };

  /**
   * This method is to handle the section select change event
   * @param {*} value 
   */
  const handleSectionChange = (value) => {
    let subSections = _.map(_.filter(sectionData, { id: value }), "children");
    if (subSections?.length) {
      setSubSectionData(subSections[0]);
    }
  };

  useEffect(() => {
    handleCategoryChange(category);
  }, [category, categoryData]);

  useEffect(() => {
    handleSectionChange(section);
  }, [section, sectionData]);

  /**
   * This method is initialize the section and subsection details to move the prompt
   */
  const moveInstantInsightPrompt = () => {
    let selectedDetails = {
      category: _.pick(_.filter(categoryData, { id: category })[0], [
        "id",
        "value",
      ]),
      section: _.pick(_.filter(sectionData, { id: section })[0], [
        "id",
        "value",
      ]),
      subSection: _.pick(_.filter(subSectionData, { id: subSection })[0], [
        "id",
        "value",
      ]),
    };
    dispatch(
      setTargetSubSection({
        targetSubSection: selectedDetails,
      })
    );
    props.continueMoveInstantInsightPrompt(false);
  };

  /**
   * This method will be closed the modal
   */
  const btnModalCloseClick = () => {
    props.closeMovePrompt(false);
  };

  return (
    <div>
      <MotifModal
        id="motifModalMoveInstantInsightPrompt"
        className="genericModal"
        size="lg"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={btnModalCloseClick}
      >
        <MotifModalHeader>
          <div className="share-alert-header-title">
            Move selected prompt(s)
          </div>
        </MotifModalHeader>
        <MotifModalBody>
            <div className="ii-move-prompt-header-container">
              <span className="ii-move-prompt-header">
                Please select a location
              </span>
            </div>
            <div className="ii-move-prompt-modal-row">
              <span className="ii-move-prompt-modal-label">Category</span>
              <MotifFormField>
                <MotifSelect
                  className="Input"
                  ariaLabelledBy="select-label"
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  value={category}
                >
                  {categoryData?.map((category) => {
                    return (
                      <MotifOption value={category.id}>
                        {category.value}
                      </MotifOption>
                    );
                  })}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="ii-move-prompt-modal-row">
              <span className="ii-move-prompt-modal-label">Section</span>
              <MotifFormField>
                <MotifSelect
                  className="Input"
                  ariaLabelledBy="select-label"
                  onChange={(value) => {
                    setSection(value);
                    setSubSection(0);
                  }}
                  value={section}
                >
                  {sectionData?.map((section) => {
                    return (
                      <MotifOption value={section.id}>
                        {section.value}
                      </MotifOption>
                    );
                  })}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="ii-move-prompt-modal-row">
              <span className="ii-move-prompt-modal-label">Subsection</span>
              <MotifFormField>
                {subSection === 0 ? (
                  <MotifLabel>Select SubSection</MotifLabel>
                ) : null}
                <MotifSelect
                  className="Input"
                  ariaLabelledBy="select-label"
                  value={subSection}
                  onChange={(value) => {
                    setSubSection(value);
                  }}
                >
                  {subSectionData?.map((subSection) => {
                    return (
                      <MotifOption value={subSection.id}>
                        {subSection.value}
                      </MotifOption>
                    );
                  })}
                </MotifSelect>
              </MotifFormField>
            </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  btnModalCloseClick();
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                disabled={
                  subSection === 0 ||
                  subSection === props.selectedSubSection?.id
                }
                onClick={moveInstantInsightPrompt}
              >
                Continue
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};
export default MoveInstantInsightPromptModal;
