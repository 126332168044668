export const CreateAction= "N";
export const EditAction= "Y";
export const EditSuccessMesage= "PROJECT UPDATED SUCCESSFULLY";
export const CreateProjectHeading= "Project details";
export const EditProjectHeading= "Details";
export const StyleType= "relative";
export const ProjectCreatedMessage= "Y";
export const PromptType = { Personal:"Personal", SatBestPractice:"SaT Best Practice" };
export const ProjectApprovalTextPart1= "Engagement teams must read the";
export const ProjectApprovalTextPart2= "to confirm permissibility to use the platform on their engagement and understand other quality and risk management considerations prior to creating a project in Digital Diligence Assistant.";
export const ProjectAIDisclaimerTextPart1= "Please always be mindful of your obligations as an EY Professional and use Digital Diligence Assistant responsibly in line with the";
export const ProjectAIDisclaimerTextPart2= "Always make sure that you (a) have the required rights and permissions to use data with Digital Diligence Assistant and (b) validate any outputs prior to its use. Use of Digital Diligence Assistant must be in line with the Service Line requirements as outlined in the Engagement Management Guidance and any EY work product using such output must be reviewed for accuracy and validity and follow all country and/or Service Line specific quality processes and guidelines.";
export const restrictedLocation= ["China", "Taiwan", "Kazakhstan"];
export const dashboardLocation="/DASHBOARD";
export const restrictedTooltipData="Please check the Engagement Management Guidance for engagement location permissibility";
export const ProjectEngagement ="Please confirm that the selected engagement location is correct. If a change is required please return to the Dashboard to select the appropriate engagement location."
export const ProjectEngagementNote ="Note: The selected engagement location should reflect the location of the EY member firm that holds the engagement agreement with the client - it is not the location of the Target / Client company itself.";
export const PromptHeader = "Prompt:";
export const ResponseHeader = "Response:";
export const InstantInsightTopics = [{id:1, name: 'Report Sections'},{id:-1, name: 'Red-Flag Items'},{id:-2, name: 'Management Follow-up questions'}]
export const rolePopheader = "Roles";
export const rolePopUpNonSatPart1 = "User (Non-SaT) - able to use the functionality, view documents and ";
export const rolePopUpNonSatPart2 = "refresh Instant Insights.";
export const rolePopUpSatPart1 = "User (SaT) - has all the capabilities of a User (Non-SaT), plus is able";
export const rolePopUpSatPart2 = "to add documents, download documents and run Instant Insights.";
export const rolePopUpAdminPart1 = "Admin - has all the capabilities of a User (SaT), plus is able to edit";
export const rolePopUpAdminPart2 = "project details, add/remove members in a project, change a";
export const rolePopUpAdminPart3 = "member's role and close a project.";