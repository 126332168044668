import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import "../../../dashboard/ClosePopup.css";
import MemberService from "../../../../services/memberService";
import icon_warning from "../../../../assets/image/warning-triangle_red.svg";
import "../../../ProjectDashboard/InstantInsights/InstantInsights.css";
import PlatformService from "../../../../services/platformService";

export default function DeleteConfirm(props) {
  const platformService = new PlatformService();

  const btnConfirmClick = async () => {
    const platformPromptIds = props.promptsSelected
      .map((item) => item.platformPromptId)
      .join(",");
    let platformPromptRequest = {};
    platformPromptRequest.PlatformPromptIds = platformPromptIds;
    await platformService.DeletePrompts(platformPromptRequest);
    props.setshowDeletedComplete(true);
    props.setShowDeleteConfirmPopup(false);
  };

  const btnModalCloseClick = () => {
    props.setShowDeleteConfirmPopup(false);
  };

  return (
    <MotifModal
      id="motifModalAcknowledgeRefresh"
      className="motif-modal-confidential"
      size="md"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={btnModalCloseClick}
    >
      <div className="ii-acknowledgement-header-div">
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Close popup",
          }}
        >
          <div className="ii-acknowledgement-header-icon-div">
            <img className="icon-refresh" src={icon_warning}></img>
          </div>
        </MotifModalHeader>
        <div className="ii-acknowledgement-title">Delete Prompt</div>
      </div>

      <MotifModalBody>
        <div className="li-del-column">
          <div>Confirm you want to permanently delete</div>
          <div>
            <ul style={{ listStyleType: "disc" }} className="li-deletemodel-ul">
              {props.promptsSelected.length > 0 &&
                props.promptsSelected?.map((prop) => {
                  return (
                    <li className="li-delete-bullet">
                      {" "}
                      {prop.platformPromptTitle}{" "}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div>This action cannot be undone.</div>
        </div>
      </MotifModalBody>
      <MotifModalFooter>
        <div className="btns-div">
          <div>
            <MotifButton
              id="btnCancel"
              variant="primary-alt"
              onClick={btnModalCloseClick}
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton id="btnAcknowledge" onClick={btnConfirmClick}>
              Confirm
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
}
