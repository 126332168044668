import React, { useState } from "react";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";

const PromptAccordion = (props) => {
  const [open, setOpen] = useState(false);

  /**
   * This method is a click operation of the accordion click
   * @param {*} event
   */
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom") {
      props.onCheckPrompt(props?.prompt?.platformPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon"
    ) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, false);
    } else if (event.target.nodeName === "svg" || event.target.nodeName === "path") {
      setOpen(!open);
    } else if (
      event.target.nodeName === "svg" ||
      (event.target.className?.trim() !== "ii-prompt-detail-text" &&
        event.target.className?.trim() !== "motif-accordion-content")
    ) {
      setOpen(!open);
    }
  };

  return (
    <MotifAccordion
      open={open}
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => {}}
      onOpen={() => {}}
      onClick={($event) => onClickAccordion($event)}
      className={
        props?.prompt?.isChecked
          ? "ii-prompt-selected-accordion"
          : "ii-prompt-accordion"
      }
      data-testid="accordionPromptTitle"
    >
      <MotifAccordionTrigger>
        <div className="ii-response-accordion-trigger">
          {(() => {
            if (
              props.tab === "SystemPrompts" &&
              props.type === "activePrompt"
            ) {
              return "";
            } else {
              return (
                <MotifCheckbox
                  name="chkPrompt"
                  checked={props?.prompt?.isChecked}
                  value={props?.prompt?.isChecked}
                ></MotifCheckbox>
              );
            }
          })()}
          {props?.prompt?.platformPromptTitle}
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        <div className="ii-prompt-detail-text">
          {props?.prompt?.platformPromptText}
        </div>
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

export default PromptAccordion;
