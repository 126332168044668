import React, { useEffect, useState, useContext } from "react";
import { DashboardContext } from "../../context/dashboardContext";
import Utils from "../../utils/Utils";
import _ from "lodash";
import { MotifToast } from "@ey-xd/motif-react";
import DocumentService from "../../services/documentService";
import loadingIndicatorIcon from "../../assets/image/loading-indicator-circles.svg";
import checkBlueIcon from "../../assets/image/check-blue.svg";
import doubleCheckIcon from "../../assets/image/double-check.svg";

const CommonComponent = () => {
  const {
    isDocumentDeleteProcessInitiated,
    setIsDocumentDeleteProcessInitiated,
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
    setShowDocumentUploadMessage,
    showDocumentUploadMessage,
    isLocalUploadInitiated,
    isSharepointUploadInitiated,
    setDocumentList,
    setIsLocalUploadInitiated,
    setIsSharepointUploadInitiated,
    documentUploadSubmitted,
    refreshUploadMessage,
  } = useContext(DashboardContext);
  const utils = new Utils();
  let interval = null;
  const documentservice = new DocumentService();

  const [documentName, setdocumentName] = useState("");
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [isUploadInProgressDisplayed, setIsUploadInProgressDisplayed] =
    useState(false);
  const [showUploadInitiated, setShowUploadInitiated] = useState(false);
  const [showUploadInPorgress, setShowUploadInPorgress] = useState(false);
  const [showUploadComplete, setShowUploadComplete] = useState(false);
  const [currentUploadType, setCurrentUploadType] = useState("");

  /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to check the status of the recently deleted document.
   */
  useEffect(() => {
    if (isDocumentDeleteProcessInitiated) {
      let deleteDocInterval = setInterval(async () => {
        const documentName = await utils.getdeleteDocumentName();
        if (documentName.length !== 0) {
          setdocumentName(documentName);
          setshowDeletedComplete(true);
          setIsDocumentDeleteProcessInitiated(false);
          clearInterval(deleteDocInterval);
        }
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);
      return () => {
        clearInterval(deleteDocInterval);
      };
    }
  }, [isDocumentDeleteProcessInitiated]);

  /**
   * This use effect will call on component load to show the upload message
   */
  useEffect(() => {
    async function getUploadProgressData() {
      let documentProcessDetails =
        await documentservice.getDocumentUploadProcessDetail(
          sessionStorage.getItem("DashboardProjectId")
        );
      if (documentProcessDetails) {
        if (documentProcessDetails?.flag === "True") {
          setCurrentUploadType(documentProcessDetails?.type);
          setIsDocumentUploadProcessInitiated(true);
          //getDocumentDetails();
          // if (documentProcessDetails?.type === "SharePoint") {
          //   setShowUploadInPorgress(true);
          // } else {
          //   getDocumentDetails();
          // }
        } else {
          setShowUploadComplete(true);
        }
      }
    }
    getUploadProgressData();
  }, []);
  /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to check the status of the recently uploaded document.
   */
  useEffect(() => {
    showUploadMessageProcess();
  }, [isDocumentUploadProcessInitiated, documentUploadSubmitted]);

  /**
   * Description
   * -----------
   * This useEffect will check if document message is not displayed and will show message document upload in progress.
   */
  useEffect(() => {
    if (showDocumentUploadMessage && isUploadInProgressDisplayed) {
      setIsUploadInProgressDisplayed(false);
      setShowUploadInitiated(false);
      setShowUploadInPorgress(true);
    }
  }, [showDocumentUploadMessage]);

  const showUploadMessageProcess = () => {
    if (isDocumentUploadProcessInitiated) {
      setShowUploadInitiated(false);
      setShowUploadInPorgress(false);
      setShowUploadComplete(false);
      if (isLocalUploadInitiated) {
        setShowUploadInitiated(true);
      }
      if (isSharepointUploadInitiated) {
        setShowUploadInPorgress(true);
      }
      interval = setInterval(() => {
        getDocumentDetails();
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);

      return () => {
        clearInterval(interval);
      };
    }
  };

  /**
   * This useEffect will refresh the upload messages if user reload documents from the grid
   */
  useEffect(() => {
    if (isDocumentUploadProcessInitiated) {
      getDocumentDetails();
    }
    return () => {
      setIsDocumentUploadProcessInitiated(false);
      setIsLocalUploadInitiated(false);
      setIsSharepointUploadInitiated(false);
      setShowDocumentUploadMessage(false);
      if (interval) {
        clearInterval(interval);
      }
    };
    // async function getRefreshDocumentProgressData() {
    //   if (isDocumentUploadProcessInitiated) {
    //     let documentProcessDetails =
    //       await documentservice.getDocumentUploadProcessDetail(
    //         sessionStorage.getItem("DashboardProjectId")
    //       );
    //     if (documentProcessDetails) {
    //       if (documentProcessDetails?.flag === "True") {
    //         if (documentProcessDetails?.type === "SharePoint") {
    //           setShowUploadInPorgress(true);
    //           setShowUploadInitiated(false);
    //         } else {
    //           getDocumentDetails();
    //         }
    //       } else {
    //         setShowUploadComplete(true);
    //         if (interval) {
    //           clearInterval(interval);
    //         }
    //       }
    //     }
    //   }
    // }
    // getRefreshDocumentProgressData();
  }, [refreshUploadMessage, currentUploadType]);

  /**
   * This method will return the document details with its status to show the upload message based on status.
   */
  const getDocumentDetails = async () => {
    const uploadDocuments = await documentservice.getDocuments(
      sessionStorage.getItem("DashboardProjectId")
    );
    if (uploadDocuments.length > 0) {
      setDocumentList(uploadDocuments);

      if (
        _.some(
          uploadDocuments,
          (docs) => docs.status === "InProgress" || docs.status === "Processing"
        )
      ) {
        const notCompletedDocs = _.filter(
          uploadDocuments,
          (docs) => docs.status === "InProgress" || docs.status === "Processing"
        );
        if (_.every(notCompletedDocs, { status: "Processing" })) {
          if (!showDocumentUploadMessage) {
            setShowDocumentUploadMessage(true);
            setIsUploadInProgressDisplayed(true);
          }
        } else {
          if (currentUploadType === "Local") {
            setShowUploadInitiated(true);
            setShowUploadInPorgress(false);
          }
          if (currentUploadType === "SharePoint") {
            setShowUploadInPorgress(true);
            setShowUploadInitiated(false);
          }
        }
      } else {
        setShowUploadInitiated(false);
        setShowUploadInPorgress(false);
        setShowUploadComplete(true);
        setIsDocumentUploadProcessInitiated(false);
        setIsLocalUploadInitiated(false);
        setIsSharepointUploadInitiated(false);
        setShowDocumentUploadMessage(false);
        if (interval) {
          clearInterval(interval);
        }
      }
    }
  };

  const acknwoledgeUploadComplete = () => {
    documentservice.deleteFileUploadProgress(
      sessionStorage.getItem("DashboardProjectId")
    );
    setShowUploadComplete(false);
  };

  return (
    <div>
      {showDeletedComplete && (
        <MotifToast
          variant="success"
          actionName=""
          position="bottom"
          onClose={() => {
            setshowDeletedComplete(false);
          }}
        >
          <div>{documentName} has been successfully deleted.</div>
        </MotifToast>
      )}
      {showUploadInitiated && (
        <MotifToast
          id="toastUploadInitiated"
          variant="error"
          actionName=""
          position="top"
          onClose={() => {
            setShowUploadInitiated(false);
          }}
          closeButtonProps={{ style: { display: "none" } }}
          icons={{
            toast: (
              <img
                src={loadingIndicatorIcon}
                alt=""
                className="rotate-icon"
              ></img>
            ),
          }}
        >
          <div>
            Upload in progress. You will be notified when upload has been
            completed. Do not exit Digital Diligence Assistant.
          </div>
        </MotifToast>
      )}
      {showUploadComplete && (
        <MotifToast
          id="toastUploadComplete"
          variant="success"
          actionName=""
          position="top"
          onClose={() => {
            acknwoledgeUploadComplete();
          }}
          icons={{ toast: <img src={doubleCheckIcon} alt=""></img> }}
        >
          <div>
            Processing Complete. Your documents are now ready for prompt.
          </div>
        </MotifToast>
      )}
      {showUploadInPorgress && (
        <MotifToast
          id="toastLocalUploadInPorgress"
          variant="info"
          actionName=""
          position="top"
          onClose={() => {
            setShowUploadInPorgress(false);
          }}
          closeButtonProps={{ style: { display: "none" } }}
          icons={{ toast: <img src={checkBlueIcon} alt=""></img> }}
        >
          <div>
            Upload complete. Getting your documents ready for prompt. You can
            now exit Digital Diligence Assistant.
          </div>
        </MotifToast>
      )}
    </div>
  );
};

export default CommonComponent;
