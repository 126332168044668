import { useContext, useEffect, useState, useRef } from "react";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifRichTextEditor from '@ey-xd/motif-react/RichTextEditor';
import "./Response.css";
import copytag from "../../../assets/image/copy.svg";
import editIcon from "../../../assets/image/editResponse.svg";
import transferIcon from "../../../assets/image/data-transfer-both.svg";
import ExploreService from "../../../services/exploreService";
import { DashboardContext } from "../../../context/dashboardContext";
import Utils from "../../../utils/Utils";
import LikeDislike from "../../common/LikeDislike";

const EditResponse = (props) => {
  const { isPromptSubmitted, setIsPromptSubmitted,
    latestPromptId, setLatestPromptId,
    showViewSourceDocIconOnEditButtonClick, setShowViewSourceDocIconOnEditButtonClick, 
    flipValueToTriggerRerender, setFlipValueToTriggerRerender } = useContext(DashboardContext);
  const { onCopy, onTransfer, transferIconShow } = props;

  const explorservice = new ExploreService();
  let { setHidePromptSearchInput } = useContext(DashboardContext);
  const [response, setResponse] = useState(null);
  const [modifiedResponse, setModifiedResponse] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const [isResponseEdited, setIsResponseEdited] = useState(false);
  const motifReactEditorModules = {
    toolbar: false,
    clipboard: {
      matchVisual: false
    }
  };
  const contentDivRef = useRef();
  const utils = new Utils();


  /**
   * Function to print response as characterwise
   * @param {*} sentence 
   * @param {*} speed 
   */
  const printSentence = (sentence, vPromptId, speed = 50) => {
    let index = 0;
    let element = document.getElementsByClassName('divModifiedResponse' + vPromptId)[0];
    // let elementRef = contentDivRef.current;
    
    const chatLayer = document.getElementById('id-scroll-explore-data-container');
    chatLayer.scrollTop = chatLayer.scrollHeight;

    let timer = setInterval(function () {
      const char = sentence[index];
      if (char === '<') {
        index = sentence.indexOf('>', index);
      }
      element.innerHTML = sentence.slice(0, index);
      if (++index === sentence.length) {
        clearInterval(timer);
      }
    }, speed);
  }

  useEffect(() => {
    setResponse(props.response);
    setModifiedResponse(props.response);
  }, [props.response]);

  /**
   * UseEffect hook to check if prompt submitted to call print sentence
   */
  useEffect(() => {
    if (isPromptSubmitted && latestPromptId && modifiedResponse && props.promptId && (latestPromptId === props.promptId)) {
      //  Coverting to markdown before printing sentences
      const vResponse = utils.markdownToHtml(modifiedResponse);
      printSentence(vResponse, latestPromptId, 20);
      setIsPromptSubmitted(false);
      setLatestPromptId(null);
    }
  }, [isPromptSubmitted, latestPromptId, modifiedResponse, props.promptId]);

  /**
   * This method updates the modified response state with latest value
   * @param updatedResponse - recently updated response
   */
  const onModifiedResponseChange = (updatedResponse) => {
    setModifiedResponse(updatedResponse);
  };

  /**
   * This method enables the save button we user type something
   */
  const onResponseEdited = () => {
    setIsResponseEdited(true);
  }

  /**
   * Allow special keys like Tab, Enter, Delete, Backspace & Control char combination to enable Save button
   */
  const onResponseEditKeyUp = (e) => {
    const key = e?.key;
    const nativeEvent = e?.nativeEvent;
    if (key === "Tab" || key === "Enter" || key === "Delete" || key === "Backspace" || (nativeEvent?.composed && nativeEvent?.ctrlKey)) {
      setIsResponseEdited(true);
    }
  }

  /** START - View Source Document Visible Function **/
  
  const onSaveClickUpdateAndRemoveViewSourceDocIconIdsToState = (promptId) => {
    let viewSourceDocIconOnEditButtonClick = showViewSourceDocIconOnEditButtonClick;
    if (viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] !== undefined
      && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === false) {
      viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] = true;
      setShowViewSourceDocIconOnEditButtonClick(viewSourceDocIconOnEditButtonClick);
      if (viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === true) {
        const { [`viewSourceDocument${promptId}`]: _, ...newObject } = viewSourceDocIconOnEditButtonClick;
        setShowViewSourceDocIconOnEditButtonClick(newObject);
        setFlipValueToTriggerRerender(!flipValueToTriggerRerender);
      }
    }
  }

  const onCancelClickUpdateAndRemoveViewSourceDocIconIdsToState = (promptId) => {
    let viewSourceDocIconOnEditButtonClick = showViewSourceDocIconOnEditButtonClick;
    if (viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] !== undefined
      && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === false) {
      viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] = true;
      setShowViewSourceDocIconOnEditButtonClick(viewSourceDocIconOnEditButtonClick);
      if (viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === true) {
        const { [`viewSourceDocument${promptId}`]: _, ...newObject } = viewSourceDocIconOnEditButtonClick;
        setShowViewSourceDocIconOnEditButtonClick(newObject);
      }
    }
  }

  const onEditClickAddViewSourceDocIconIdsToState = (promptId) => {
    let viewSourceDocIconOnEditButtonClick = showViewSourceDocIconOnEditButtonClick;
    viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] = false;
    setShowViewSourceDocIconOnEditButtonClick(viewSourceDocIconOnEditButtonClick);
  }

  /** END - View Source Document Visible Function **/

  /**
   * This method calls the backend api to save the modified response
   */
  const onSaveClick = async () => {
    const request = {
      promptId: props.promptId,
      modifiedResponse: modifiedResponse,
    };
    const result = await explorservice.updatePromptResponse(request);
    if (result) {
      setEnableEdit(false);
      setIsResponseEdited(false);
      setResponse(modifiedResponse);
      props.onModifiedResponseUpdate(modifiedResponse);
      setHidePromptSearchInput(false);
      onSaveClickUpdateAndRemoveViewSourceDocIconIdsToState(props.promptId);
    }
  };

  /**
   * This method discards the changes done in the editor
   */
  const onCancelEdit = () => {
    setEnableEdit(false);
    setModifiedResponse(response);
    setIsResponseEdited(false);
    setHidePromptSearchInput(false);

    onCancelClickUpdateAndRemoveViewSourceDocIconIdsToState(props.promptId);
  };

  /**
   * This method updates the user action.
   * @param userAction: updated user action
   * @param oResponse: props object
   */
  const updateUserAction = (userAction, props) => {
    // Add your logic here to update the user action
    props.userAction = userAction;
  };

  /**
   * This method enables the editing mode to modify the response
   */
  const enableResponseEdit = () => {
    setEnableEdit(true);
    setHidePromptSearchInput(true);
  };

  return (
    <>
      {!enableEdit && (
        <div>
          <div className="explore-prompt-question-response-container-child" data-testid="testIdResponse">
            <div
              className={
                "explore-prompt-question-response divModifiedResponse" +
                props?.promptId
              }
              dangerouslySetInnerHTML={{
                __html: utils.markdownToHtml(modifiedResponse),
              }}
              ref={contentDivRef}
            ></div>
          </div>
          <div className="response-button">
            <div className="response-icon gapbetweenicons">
              <div style={{ display: props.isModifiedResponse ? 'none' : 'block' }} >
                <LikeDislike data={{ promptHistoryId: props.promptId, userAction: (props.userAction ?? 0) }} type='exploreservice'
                  onChangeUserAction={(userAction) => { userAction.preventDefault(); updateUserAction(userAction, props); }} />
              </div>
              <MotifTooltip
                placement="bottom"
                hideCloseButton={true}
                trigger={
                  <MotifIconButton data-testid='testIdModifiedResponseCopy'>
                    <img src={copytag}
                      className="icon"
                      id="testIdModifiedResponseCopy"
                      alt="Copy"
                      onClick={(e) => {
                        e.preventDefault();
                        onCopy("divModifiedResponse" + props?.promptId);
                      }} />
                  </MotifIconButton>}>
                <div className="tooltip-useraction">
                  Copy
                </div>
              </MotifTooltip>
              {transferIconShow && (
              <div className="response-icon gapbetweenicons transfer-icon">
              <MotifTooltip
                placement="bottom"
                hideCloseButton={true}
                trigger={
                  <MotifIconButton data-testid="testIdResponseTransfer" className="transfer-icon-button">
                    <img src={transferIcon}
                      className="icon edit"
                      id="testIdResponseTransfer"
                      alt="Transfer to PowerPoint"
                      onClick={(e) => {
                        e.preventDefault();
                        onTransfer(props?.promptId,props?.isModifiedResponse ? "M" : "E");// M is for Eyd modified response and E is for original response
                      }} />
                  </MotifIconButton>}>
                <div className="tooltip-useraction">
                  Transfer to PowerPoint
                </div>
              </MotifTooltip>
              </div>
              )}
              <div className="response-icon gapbetweenicons edit-icon">
                <MotifTooltip
                  placement="bottom"
                  hideCloseButton={true}
                  trigger={
                    <MotifIconButton data-testid='testIdModifiedResponseEdit' className="edit-icon-button">
                      <img src={editIcon}
                        className="icon"
                        id="testIdOriginalResponseEdit"
                        alt="edit response"
                        onClick={() => enableResponseEdit(props?.promptId)} />
                    </MotifIconButton>}>
                  <div className="tooltip-useraction">
                    Edit
                  </div>
                </MotifTooltip>
              </div>
            </div>
          </div>
        </div>
      )}

      {enableEdit && (
        <div className="modified-response-container" id={`prompt-editor${props?.promptId}`}>
          <MotifRichTextEditor
            value={modifiedResponse}
            modules={motifReactEditorModules}
            onChange={onModifiedResponseChange}
            onKeyPress={onResponseEdited}
            onKeyUp={onResponseEditKeyUp}
          />
          <div className="explore-phase-edit-response-container">
            <div className="edit-response-container">
              <MotifButton
                label="btnSave"
                onClick={onCancelEdit}
                className="CancelButton"
              >
                Cancel
              </MotifButton>
              <MotifButton
                label="btnSave"
                onClick={onSaveClick}
                disabled={!isResponseEdited}
              >
                Save
              </MotifButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditResponse;
