import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import MotifTable from "@ey-xd/motif-react/Table";
import { MotifButton, MotifFormField, MotifSearch, MotifToast } from "@ey-xd/motif-react";
import "./dashSuperAdmin.css";
import { DashboardContext } from "../../context/dashboardContext";

import FilterPlatformAdmin from "./filterPlatformAdmin.js";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MotifMenu from "@ey-xd/motif-react/Menu";
import clock from "../../assets/image/clock.svg";
import download from "../../assets/image/download.svg";
import filter_List from "../../assets/image/filter-list.svg";
import arrowright from "../../assets/image/arrow-right-black.svg";
import UserLogService from "../../services/userLogService.js";
import check_circle from "../../assets/image/check-circle.svg";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils/Utils";
import ExcelUtils from "../../utils/excelUtils";

const projectMasterData = require("../../data/projectMasterData.js");

const DashSuperAdmin = () => {
  const dateHelper = new Utils();
  const excelUtils = new ExcelUtils();
  const navigate = useNavigate();
  const [showUserLogExportDone, setshowUserLogExportDone] = useState(false);
  const [showProjectDetailsExportDone, setshowProjectDetailsExportDone] =
    useState(false);
  const userLogService = new UserLogService();
  //context set
  let { allProjects } = useContext(DashboardContext);
  let dataHostingList = useState([]);

  //use state
  const [rowData, setRowData] = useState(allProjects);
  const [filteredRowData, setFilteredRowData] = useState([]);

  const [searchText, setsearchText] = useState("");
  const [showMenu, setshowMenu] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    manipulateData(allProjects);
  }, [allProjects]);

  function getTodaysDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + mm + yyyy;
    return formattedToday;
  }
  function ExportToExcel(apiData) {
    let header = [
      "Project type",
      "PACE ID",
      "Engagement ID",
      "Project name",
      "Client name",
      "Created On",
      "Status",
      "Owner",
      "Area",
      "Region",
      "Country",
      "Deal size (USD)",
      "Target sector",
      "Target sub-sector",
      "Target sub-sub-sector",
      "Data hosting",
      "Project Admin(s)",
      "Project User(s)",      
    ];

    let finaldata = [];

    apiData?.forEach(function (entry) {
      let singleObj = {
        projectType: entry.projectType,
        paceId: entry.paceId !== 0 ? entry.paceId : "",
        engagementID: entry.engagementId !== 0 ? entry.engagementId : "",
        projectName: entry.projectName,
        clientName: entry.clientName,
        createdOn: new Date(entry.createTS),
        status: entry.status,
        owner: entry.owner,
        area: projectMasterData.AreaConfig?.find((a) => a?.id === entry.area)
          ?.content,
        region: projectMasterData.RegionConfig?.find(
          (a) => a?.id === entry.region
        )?.content,
        country: projectMasterData.CountryConfig?.find(
          (a) => a?.id === entry.country
        )?.content,
        dealSize: projectMasterData.DealConfig?.find(
          (a) => a?.id === entry.dealSize
        )?.content,
        targetSector: projectMasterData.SectorConfig?.find(
          (a) => a?.id === entry.targetSector
        )?.content,
        targetSubSector: projectMasterData.SubSectorConfig?.find(
          (a) => a?.id === entry.target_SubSector
        )?.content,
        targetSubSubSector: projectMasterData.SubSubSectorConfig?.find(
          (a) => a?.id === entry.target_Sub_SubSector
        )?.content,
        dataHosting: projectMasterData.DataHostingListConfig?.find(
          (a) => a?.id === parseInt(entry?.dataHostingLocation)
        )?.content,
        projectAdmin: entry.admin,
        projectUser: entry.viewer,        
      };

      finaldata.push(singleObj);
    });

    let Fname = `Project Details_${getTodaysDate()}`;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(finaldata);
    XLSX.utils.sheet_add_aoa(ws, [header]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, Fname + fileExtension);
    setshowProjectDetailsExportDone(true);
  }

  const onClickViewProject = (projectId, projectName, dataHostingLocation) => {
    sessionStorage.setItem("DashboardProjectId", projectId);
    sessionStorage.setItem("DashboardProjectName", projectName);
    sessionStorage.setItem("DataHostingLocation", dataHostingLocation)
    navigate("/ViewProjectDetail");
  };

  async function handleExportUserLog(e) {
    e.preventDefault();
    await userLogService.getUserLogDetails().then((res) => {
      let header = ["First name", "Last name", "Email ID", "Date", "Time"];
      let finaldata = [];
      res?.forEach(function (entry) {
        let singleObj = {
          firstName: entry.firstName,
          lastName: entry.lastName,
          email: entry.email,
          date: entry.loginDate,
          time: entry.loginTime,
        };
        finaldata.push(singleObj);
      });

      let Fname = `User Log_${getTodaysDate()}`;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(finaldata, {raw:false});
      excelUtils.formatColumnAsDate(ws, 3, "dd/mm/yyyy");
      excelUtils.formatColumnAsTime(ws, 4, "hh:mm:ss");
      ws["!cols"] = [{wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}];
      XLSX.utils.sheet_add_aoa(ws, [header]);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Fname + fileExtension);
      setshowUserLogExportDone(true);
    });
  }

  useEffect(() => {
    let filterRowData = rowData;
    if (filterRowData) {
      if (filteredData.startDate && filteredData.endDate) {
        filterRowData = filterRowData.filter(
          (item) =>
          new Date(item?.createdOn) >= new Date(filteredData.startDate) &&
          new Date(item?.createdOn) <= new Date(filteredData.endDate)
        );
      }

      if (filteredData.status?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.status?.includes(item.status)
        );
      }

      if (filteredData.sector?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.sector?.includes(item.targetSector)
        );
      }

      if (filteredData.subsector?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.subsector?.includes(item.target_SubSector)
        );
      }

      if (filteredData.subsubsector?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.subsubsector?.includes(item.target_Sub_SubSector)
        );
      }

      if (filteredData.area?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.area?.includes(item.area)
        );
      }

      if (filteredData.region?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.region?.includes(item.region)
        );
      }

      if (filteredData.country?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.country?.includes(item.country)
        );
      }

      if (filteredData.dealSize?.length) {
        filterRowData = filterRowData.filter((item) =>
          filteredData.dealSize?.includes(item.dealSize)
        );
      }
    }
    setFilteredRowData(filterRowData);

  }, [filteredData, rowData]);

  function handleFilterProject(filterData) {
    if (filterData?.dealSize?.length > 0) {
      let diffElement = _.difference(
        filterData.dealSize,
        filteredData.dealSize
      );
      if (diffElement) {
        filterData.dealSize?.push(...diffElement);
      }
    }
    if (filterData?.status?.length > 0) {
      let diffElement = _.difference(filterData.status, filteredData.status);
      if (diffElement) {
        filterData.status?.push(...diffElement);
      }
    }
    setFilteredData(filterData);
  }

  function dateFormat(createdOnDatetime) {
    const d = new Date(createdOnDatetime);
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();
    let finalDate = day + "/" + month + "/" + year;
    return finalDate;
  }
  function dateFormatUserLog(createdOnDatetime) {
    const d = new Date(createdOnDatetime);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let finalDate = day + "/" + month + "/" + year;
    return finalDate;
  }
  const manipulateData = (prjdata) => {
    prjdata?.forEach((x) => {
      x.projectType = projectMasterData.CategoryConfig?.find(
        (a) => a?.id === parseInt(x?.category)
      )?.content;

      x.createdOn = dateHelper.convertDateToUTCTimeStamp(x.createTS);
    });
    //Sort the result based on created date descending order
    prjdata.sort((pre, cur) => {
      if(new Date(pre.createdOn) < new Date(cur.createdOn)) {
        return 1;
      }
      else {
        return -1;
      }
    });
    setRowData(prjdata);
  };

  function handleFilterIconClick(e) {
    setshowMenu(true);
  }
  function handleMenuClose() {
    setshowMenu(false);
  }

  function texhchnage_test(e) {
    setsearchText(e.target.value);
    const srch = e.target.value;

    if (e.target.value !== "") {
      let result1 = allProjects.filter(
        (item) =>
          item?.projectName?.toLowerCase()?.includes(srch.toLowerCase()) ||
          item?.paceId
            ?.toString()
            ?.toLowerCase()
            ?.includes(srch.toLowerCase()) ||
          item?.engagementId
            ?.toString()
            ?.toLowerCase()
            ?.includes(srch.toLowerCase())
      );

      // setRowData(result1?.length > 0 ? result1 : filteredRowData);
      setRowData(result1);
      setFilteredRowData(result1?.length ? result1 : filteredRowData);
    } else {
      setRowData(allProjects);
      setFilteredRowData(allProjects);
    }
  }

  /**
   * Description
   * -----------
   * This method formats the required date of grid column before displaying.
   * Parameters:
   * params: contains the date.
   */
  const dateFormatter = (params) => {
    return dateHelper.formatDateCustom(params.value);
  };

  function reloadGridData() {
    manipulateData(allProjects);
  }
  dataHostingList = projectMasterData.DataHostingListConfig;

  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState([
    {
      field: "projectName",
      flex:4.5,
      wrapText: true,
      autoHeight: true,
      headerComponentParams: (params) => {
        return { template: `<div>Project Name` };
      },
      cellRenderer: (params) => (
        <div className="projectNameDesc">
          <div className="projectName">{params.data.projectName}</div>
          <div className="projectDesc">
            {params.data.description != null &&
              params.data.description !== "null"
              ? params.data.description
              : ""}
          </div>
        </div>
      ),
    },

    {
      field: "createdOn",
      width: 170,
      headerComponentParams: (params) => {
        return { template: `<div>Created On (UTC) ` };
      },
      valueFormatter: dateFormatter,
    },

    {
      field: "owner", 
      flex:3,
      wrapText: true,
      autoHeight: true,
    },

    {
      field: "paceId",
      width: 120,
      headerComponentParams: (params) => {
        return { template: `<div>PACE ID ` };
      },
      cellRenderer: (params) => (
        <div>{params.data.paceId !== 0 ? params.data.paceId : ""}</div>
      ),
    },

    {
      field: "engagementId",
      flex:3,
      cellRenderer: (params) => (
        <div>
          {params.data.engagementId !== 0 ? params.data.engagementId : ""}
        </div>
      ),
    },

    { 
      field: "status", 
      width: 100 ,
      cellRendererFramework: (params) => {
        if(params.data.status === "Open"){
          return (
            <span
              className="projectStatus-open"
              title={params.data.status}
            >
              {params.data.status}
            </span>
          );
        }
        else{
          return (
            <span
              className="projectStatus-close"
              title={params.data.status}
            >
              {params.data.status}
            </span>
          );
        }
      }
    },

    { field: "projectType", width: 165 },

    {
      field: "dataHostingLocation",
      width: 135,
      headerComponentParams: (params) => {
        return { template: `<div>Data Hosting ` };
      },
      cellRenderer: (params) => (
        <div>
          {dataHostingList.filter((item) => item.id == params.data.dataHostingLocation)[0]?.content}
        </div>
      ),
    },

    {
      width: 140,
      cellRenderer: (params) => (
        <div
          onClick={(e) =>
            onClickViewProject(
              params.data?.project_UUID,
              params.data?.projectName,
              params.data?.dataHostingLocation
            )
          }
        >
          <MotifButton className="motifbuttonchange1">
            View Details
            <img src={arrowright} alt="" />
          </MotifButton>
        </div>
      ),
    },
  ]);

  // Container: Defines the grid's theme & dimensions.
  return (
    <>
      <div className="main-container">
        <MotifMenu id="menuFilter" show={showMenu} onClose={handleMenuClose}>
          <div className="platformAdminFilter" id="divPlatformfilter">
            <FilterPlatformAdmin
              methodname={handleFilterProject}
              displayMenu={handleMenuClose}
              reloadGridData={reloadGridData}
              filterData={filteredData}
            ></FilterPlatformAdmin>
          </div>
        </MotifMenu>

        <div className="super-admin-container">
          <div className="super-admin-div">
            <div className="super-admin-divs">
              <div className="super-admin-top">
                <div className="super-admin-filter">
                  <MotifFormField>
                    <MotifSearch
                      className="searchIconimg"
                      aria-label="Search"
                      hideClearButton
                      id="searchText1"
                      onChange={(e) => {
                        texhchnage_test(e);
                      }}
                      placeholder="Search"
                      value={searchText}
                    />
                  </MotifFormField>
                </div>
              </div>
            </div>
          </div>
          <div className="project-header-container">
            <div className="project-header-div">
              <div className="export-logs">
                <MotifButton
                  className="motifbuttonchange1"
                  onClick={handleExportUserLog}
                >
                  <img src={clock} alt="export logs" />
                  <span className="paddingLeftimage">Export User Log</span>
                </MotifButton>
              </div>
            </div>
          </div>
        </div>

        <div className="main-container-div">
          <div className="allProjectPlatformAdmin1">
            <div className="allProjectPlatformAdmin2">
              <div className="allProjectPlatformAdmin3">
                <div className="allProjectPlatformAdmin4">
                  <div className="allProjectPlatformAdmin5">
                    <div className="allProjectPlatformAdmin6">
                      <div className="allProjectPlatformAdmin7">
                        All Projects
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="export-button">
                <div>
                  <MotifButton
                    className="motifbuttonchange"
                    onClick={(e) => ExportToExcel(filteredRowData)}
                  >
                    <img src={download} alt="export icon"></img>
                    <span className="paddingLeftimage">Export</span>
                  </MotifButton>
                </div>

                <div className="filter-container">
                  <div className="filter-div" />
                  <MotifButton
                    className="filter-button"
                    onClick={(e) => {
                      handleFilterIconClick(e);
                    }}
                  >
                    <img src={filter_List} alt="filter icon"></img>
                    <span className="paddingLeftimage">Filter</span>
                  </MotifButton>
                </div>
              </div>
            </div>
          </div>
          <div className="divMotifTable">
            <MotifTable
              columnDefs={colDefs}
              rowData={filteredData.length === 0 ? rowData : filteredRowData}
              pagination="true"
              paginationPageSize="8"
            />
          </div>
          {showUserLogExportDone && (
            <MotifToast
              className="motifToastExportdone1"
              icons={{ toast: <img src={check_circle} alt=""></img> }}
              variant="info"
              actionName=""
              position="bottom"
              id="closeUserLogExport"
              onClose={() => {
                setshowUserLogExportDone(false);
              }}
            >
              <div>
                <div className="motifToastExportdone2">User Log exported.</div>
                <div className="motifToastExportdone3">
                  Please check your system for downloads.
                </div>
              </div>
            </MotifToast>
          )}

          {showProjectDetailsExportDone && (
            <MotifToast
              className="motifToastExportdone1"
              icons={{
                toast: <img src={check_circle} alt="check circle icon"></img>,
              }}
              variant="info"
              actionName=""
              position="bottom"
              id="closeprojectExport"
              onClose={() => {
                setshowProjectDetailsExportDone(false);
              }}
            >
              <div>
                <div className="motifToastExportdone2">
                  Project Details exported.
                </div>
                <div className="motifToastExportdone3">
                  Please check your system for downloads.
                </div>
              </div>
            </MotifToast>
          )}
        </div>
      </div>
    </>
  );
};

export default DashSuperAdmin;
