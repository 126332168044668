import React, { useContext, useEffect, useState } from "react";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { MotifButton, MotifErrorMessage, MotifFooter, MotifFormField, MotifInput, MotifModal, MotifModalBody, MotifModalHeader, MotifToast } from "@ey-xd/motif-react";
import InstantInsightsService from "../../../../services/instantInsightService.js";
import { DashboardContext } from "../../../../context/dashboardContext.js";
import editPencil from "../../../../assets/image/edit-pencil.svg";
import iconError from "../../../../assets/image/error.svg";
import arrowLeft from "../../../../assets/image/arrow-left-black.svg"
import './ManageProjectScope.css';


function ManageProjectScope(props) {

    const { updateAddScopeSectionVisibility } = { ...props };
    const { scopeAll, refreshScopes, setRefreshScopes } = useContext(DashboardContext);
    const instantInsightsService = new InstantInsightsService();
    const [showAddScopeModal, setShowAddScopeModal] = useState(false);
    const [projectScopeName, setProjectScopeName] = useState('');
    const [showSpecialCharacterValidation, setShowSpecialCharacterValidation] = useState(false);
    const [showMaximumCharsValidation, setShowMaximumCharsValidation] = useState(false);
    const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    

    useEffect(() => {
        if (projectScopeName == "") {
            setIsConfirmBtnDisabled(true);
        }
        else if (showSpecialCharacterValidation) {
            setIsConfirmBtnDisabled(true);
        }
        else {
            setIsConfirmBtnDisabled(false);
        }
    }, [projectScopeName, showSpecialCharacterValidation])


    const onAddScopeBtnClick = () => {
        setShowAddScopeModal(true);
    }

    const handleClose = () => {
        setShowAddScopeModal(false);
    }

    const onProjectScopeNameInputTxtChange = (value) => {
        const regex = /^[a-zA-Z0-9 ]*$/;
        setProjectScopeName(value);
        if (regex.test(value)) {
            setShowSpecialCharacterValidation(false);
            if (value.length > 50) {
                setShowMaximumCharsValidation(true);
            }
            else {
                setShowMaximumCharsValidation(false);
            }
        }
        else {
            setShowSpecialCharacterValidation(true);
        }
    }

    const onConfirmBtnClick = async (event) => {
        let response = await instantInsightsService.AddNewScope(projectScopeName);
        if(response == 200){
            setRefreshScopes(!refreshScopes);
            setShowToast(true);
            setToastType("success");
            setToastMessage("Project scope added successfully")
        }
        setShowAddScopeModal(false);
    }

    const onCancelBtnClick = (event) => {
        setShowAddScopeModal(false);
    }

    const getRelevantCssClasses = () => {
        if (showSpecialCharacterValidation) {
            return "field motif-input-invalid";
        }
        else if (showMaximumCharsValidation) {
            return "field motif-input-blue";
        }
        else {
            return "field";
        }
    }

    const getScopes = () => {
        let markUp = scopeAll
            .sort((a, b) => a.scopeName.localeCompare(b.scopeName))
            .map((scope) => {
                return (
                    <div className="scope-div" key={scope.scopeId}>
                        <div>
                            {scope.scopeName}
                        </div>
                        <div>
                            <img alt='icon' src={editPencil} />
                        </div>
                    </div>
                )
            })
        return markUp;
    }

    const onBackBtnClick = () => {
        updateAddScopeSectionVisibility(false);
    }

    return (
        <>
            <div className="back-section project-scope-field-txt">
                <MotifIconButton className="icon-btn" onClick={onBackBtnClick}>
                    <div className="back-btn">
                        <img src={arrowLeft} alt="Back" /><span>Back</span>
                    </div>
                </MotifIconButton>
            </div>
            <div className="parent-top-heading">
                <div className="top-heading">
                    Manage Project Scope
                </div>
                <div>
                    <MotifButton className="btn-add-scope" onClick={onAddScopeBtnClick}>
                        <span className="span-btn-txt">+ Add New Project Scope</span>
                    </MotifButton>
                </div>
            </div>
            <div className="scope-section">
                <div className="section-heading project-scope-field-txt">
                    <div>
                        Project Scope Names
                    </div>
                    <div>
                        Edit
                    </div>
                </div>
                <div className="scopes-parent-div">
                    {
                        getScopes()
                    }
                </div>
                
            </div>
            {
                showToast && (
                    <MotifToast
                        variant={toastType}
                        actionName=""
                        position="bottom"
                        className=""
                        onClose={() => {
                            setShowToast(false);
                        }}
                    >
                        <div>{toastMessage}</div>
                    </MotifToast>
                )
            }
            <MotifModal
                id="addScopeModal"
                show={showAddScopeModal}
                size="md"
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                onClose={() => {
                    handleClose()
                }}
            >
                <MotifModalHeader
                    closeButtonProps={{
                        "aria-label": "Close",
                        title: "Close",
                    }}
                >
                    Add New Project Scope
                </MotifModalHeader>

                <MotifModalBody>
                    <div className="project-scope-field-txt">Project Scope</div>
                    <MotifFormField className={getRelevantCssClasses()}>
                        <MotifInput
                            className="inputProjectScope"
                            onChange={(e) => onProjectScopeNameInputTxtChange(e.target.value)}
                            type='text'
                            placeholder="Enter Project Scope Name"
                            value={projectScopeName}
                            hideClearButton={false}
                        />
                        {
                            showSpecialCharacterValidation &&
                            <img src={iconError} alt='Error' className='errorIcon' />
                        }
                    </MotifFormField>
                    {
                        showSpecialCharacterValidation &&
                        <MotifErrorMessage className='errorText'>
                            Special characters not allowed
                        </MotifErrorMessage>
                    }
                    {
                        showMaximumCharsValidation &&
                        <span>Maximum limit reached - 50 characters</span>
                    }
                </MotifModalBody>

                <MotifFooter className="footer">
                    <div className="footer-btns-div">
                        <div>
                            <MotifButton
                                variant="primary-alt"
                                onClick={() => {
                                    onCancelBtnClick();
                                }}
                                className=""
                            >
                                Cancel
                            </MotifButton>
                        </div>
                        <div>
                            <MotifButton
                                className="confirm-btn"
                                onClick={onConfirmBtnClick}
                                disabled={isConfirmBtnDisabled}
                            >
                                Confirm
                            </MotifButton>
                        </div>
                    </div>
                </MotifFooter>
            </MotifModal>
        </>
    )
}

export default ManageProjectScope