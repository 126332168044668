import React, { useContext, useState } from "react";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import { DashboardContext } from "../../../../context/dashboardContext";
import ProjectScopeTree from "../../../ProjectDashboard/InstantInsights/ProjectScopeTree";
import PlatformService from "../../../../services/platformService";
import configureSetting from "../../../../assets/image/configure-setting.svg";

const PromptProjectScope = (props) => {
  const platformService = new PlatformService();
  let { scopeAll } = useContext(DashboardContext);
  const [treeData, setTreeData] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedTreeNode, setSelectedTreeNode] = useState(null);

  /**
   * This method selects the node from the project scope tree
   * @param {*} node
   */
  const handleTreeNodeClick = (node) => {
    if (props.tab === "InstantInsightPrompts") {
      props.setSelectedNode(node, treeData);
    } else {
      setSelectedItem(node.api);
      props.setSelectedNode(node, {});
    }
  };

  /**
   * On Scope selected change to fetch the Tree structure data
   */
  const onScopeChange = async (item) => {
    if (props.tab === "InstantInsightPrompts") {
      let treeData = await platformService.getScopeTreeData(item.scopeId);
      setTreeData(treeData);
    } else if (props.tab === "SystemPrompts") {
      let apiData = await platformService.getSystemPromptAPIList(
        item.scopeId,
        "A"
      );
      setTreeData(apiData);
    }
  };

  const onManageProjectScopeBtnClick = () => {
    props.updateAddScopeSectionVisibility(true);
  }

  return (
    <div className="ii-prompt-left-section">
      <div
        id="ii-prompt-project-scope"
        className="ii-prompt-project-scope-container"
      >
        <span className="ii-prompt-select-scope-label">
          Select Project Scope
        </span>
        <MotifFormField>
          {scopeAll?.length === 0 ? (
            <MotifLabel id="selectscope">No Project Scope Found</MotifLabel>
          ) : null}

          <MotifSelect
            id="scopeSelect"
            label="selectscope"
            data-testid="scopeSelect"
            className="InputInstantInsight"
            ariaLabelledBy="select-label"
            placeholder="Select Project Scope"
            onChange={onScopeChange}
            disabled={scopeAll?.length === 0}
            required
          >
            {scopeAll && [
              ...scopeAll.map((item) => {
                return (
                  <MotifOption
                    key={item.scopeName}
                    className={item.scopeName}
                    value={item}
                  >
                    {item.scopeName}
                  </MotifOption>
                );
              }),

              <MotifOption
                disabled={true}
                className="coming-soon"
                key="coming-soon"
              >
                More coming soon...
              </MotifOption>,
            ]}
          </MotifSelect>
        </MotifFormField>
      </div>
      <div className="ii-tree-container">
        {props.tab === "InstantInsightPrompts" &&
          treeData?.value?.length > 0 && (
            <ProjectScopeTree
              treeValue={treeData.value}
              showLines={true}
              treeData={treeData}
              treeNodeClick={handleTreeNodeClick}
              showCheckbox={false}
              showIcons={false}
              isFirstTimeRefreshInstantInsight={false}
              setShowAcknowledgeRefreshPopup={null}
              setSelectedTreeNode={setSelectedTreeNode}
              setLaunchedBy={null}
            ></ProjectScopeTree>
          )}

        {props.tab === "SystemPrompts" && (
          <div className="system-prompts-category-container">
            {treeData?.length > 0 &&
              treeData?.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={`system-prompts-category ${
                      item.api === selectedItem ? "active" : ""
                    }`}
                    onClick={() => {
                      handleTreeNodeClick({ api: item.api });
                    }}
                  >
                    <div className="system-prompts-category-name">
                      {item.api}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className="ii-left-menu-footer">
        <MotifButton className="btn-manage-scope" onClick={onManageProjectScopeBtnClick}>
        <img alt='icon' src={configureSetting} />
          <span className="span-btn-txt">Manage Project Scope</span>
        </MotifButton>
      </div>
    </div>
  );
};

export default PromptProjectScope;
