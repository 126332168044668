import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import trashPrompt from "../../../../assets/image/trashPrompt.svg";
import arrowRight from "../../../../assets/image/arrow-right.svg";
import eyeImage from "../../../../assets/image/eye.svg";

const PromptAction = (props) => {
  return (
    <>
      {props.tab === "InstantInsightPrompts" && props.innerTab === "active" && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnCreate"
            className="li-delete-button"
            onClick={props.onDeletePrompt}
            disabled={props.disableActionButtons}
          >
            <img src={trashPrompt} alt="Delete Prompt"></img>
            <span>Delete Prompt</span>
          </MotifButton>
          <MotifButton
            label="btnCreate"
            className="li-move-prompt"
            disabled={props.disableActionButtons}
            onClick={props.onMovePrompt}
          >
            <img src={arrowRight} alt="Move Prompt"></img>
            Move Prompt
          </MotifButton>
        </div>
      )}
      {props.tab === "SystemPrompts" && props.innerTab === "test" && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnPublish"
            className="li-move-prompt"
            disabled={props.disableActionButtons}
          >
            <img src={eyeImage} alt="Publish Prompt"></img>
            Publish
          </MotifButton>
        </div>
      )}
    </>
  );
};

export default PromptAction;
