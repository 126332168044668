import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import thumbsup from "../../assets/image/thumbsup.svg";
import thumbsdown from "../../assets/image/thumbsdown.svg";
import thumbsupactive from "../../assets/image/thumbsup_black.svg";
import thumbsdownactive from "../../assets/image/thumbsdown_black.svg";
import { MotifTooltip } from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import "./LikeDislike.css";
import ExploreService from "../../services/exploreService";
import CommonService from "../../services/commonService";
import InstantInsightsService from "../../services/instantInsightService";

const LikeDislike = (props) => {
    const [currentUserAction, setCurrentUserAction] = useState(0);
    const [likeDislikeService, setLikeDislikeTargetService] = useState(null);
    const [promptHistoryId, setPromptHistoryId] = useState(null);

    const likeActionName = "LIKE";
    const dislikeActionName = "DISLIKE";

    const getNewThumbsUpandThumbsDownValue = (currentSelectedValue, actionName) => {
        if (actionName === likeActionName) {
            let newSelectedValue = (currentSelectedValue === 1 ? 0 : 1);
            setCurrentUserAction(newSelectedValue);
            return newSelectedValue;
        } else if (actionName === dislikeActionName) {
            let resolvedValue = (currentSelectedValue === 2 ? 0 : 2);
            setCurrentUserAction(resolvedValue);
            return resolvedValue;
        }
        return 0;
    }

    useEffect(() => {
        setCurrentUserAction(props.data.userAction);
        setPromptHistoryId(props.data.promptHistoryId);
    }, [props.data.userAction, props.data.promptHistoryId]);

    useEffect(() => {
        // This is the code to instatiate the service class based on the type of service passed 
        // from the specific component that is using the LikeDislike component.
        let service;
        if (props.type === 'instantinsightsservice') {
            service = new InstantInsightsService();
        } else if (props.type === 'exploreservice') {
            service = new ExploreService();
        } else {
            let commonService = new CommonService()
            commonService.logException(
                "Invalid service type",
                "Instant Insights Component",
                "GetRefreshedInstantInsights"
            );
            return;
        }
        setLikeDislikeTargetService(service);
    }, [props.type]);

    const { onChangeUserAction } = props;
    const setLikeOrDislike = async (action) => {
        let previouslySelectedValue = currentUserAction;
        let newSelectedValue = getNewThumbsUpandThumbsDownValue(previouslySelectedValue, action);
        await likeDislikeService.upsertLikeOrDislikePromptContent(props.data.promptHistoryId,
            newSelectedValue,
        ).then(result => {
            setCurrentUserAction(result.userAction);
            setPromptHistoryId(result.promptHistoryId);

            // Updating the state of viewInstantPrompt with the new userAction.
            // This will show the icon selections on the prompt details display pages.
            if (onChangeUserAction) {
                onChangeUserAction(result.userAction);
            }

            if (!!props && !!props.data) {
                props.data.userAction = result.userAction;
            }
        }).catch(error => {
            // Handle error here
            console.error(error);
        });
    };

    return (
        <div className="gapbetweenicons">
            <div>
                <MotifTooltip
                    placement="bottom"
                    hideCloseButton={true}
                    trigger={
                        <MotifIconButton className={currentUserAction === 1 ? "thumbsupactive" : "thumbsup"}>
                            <img src={currentUserAction === 1 ? thumbsupactive : thumbsup}
                                className={currentUserAction === 1 ? "icon active" : "icon"}
                                alt="Like"
                                onClick={(event) => { event.stopPropagation(); setLikeOrDislike(likeActionName) }} />
                        </MotifIconButton>}>
                    <div className="tooltip-useraction">
                        Like
                    </div>
                </MotifTooltip>
            </div>
            <div>
                <MotifTooltip
                    placement="bottom"
                    hideCloseButton={true}
                    trigger={<MotifIconButton className={currentUserAction === 2 ? "thumbsdownactive" : "thumbsdown"}>
                        <img src={currentUserAction === 2 ? thumbsdownactive : thumbsdown}
                            className={currentUserAction === 2 ? "icon active" : "icon"}
                            alt="Dislike"
                            onClick={(event) => { event.stopPropagation(); setLikeOrDislike(dislikeActionName) }} />
                    </MotifIconButton>}>
                    <div className="tooltip-useraction">
                        Dislike
                    </div>
                </MotifTooltip>
            </div>
        </div>
    )
};
export default LikeDislike;